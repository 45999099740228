<script setup lang="ts">
import emblaCarouselVue from 'embla-carousel-vue'

const { t } = useI18n({
	useScope: 'local'
})

const { data } = useAsyncData('villages-popular', () => {
	return useAPIFetch('/v1/villages/search', {
		query: {
			'filter[position_from]': 1,
			'sort': 'position'
		}
	})
})

const [emblaMain, emblaApiMain] = emblaCarouselVue()

const onPrev = () => {
	emblaApiMain?.value?.scrollPrev()
}
const onNext = () => {
	emblaApiMain?.value?.scrollNext()
}

</script>

<template>
	<LayoutContainer
		v-if="data?.data?.length"
		class="mt-8 md:mt-12"
	>
		<h2 class="text-xl md:text-4xl px-5 md:px-0">
			{{ t('title' )}}
		</h2>
		
		<div class="relative px-4 md:px-12">
			<div
				ref="emblaMain"
				class="overflow-hidden mt-3 md:mt-8 px-[2px] pb-[4px]"
			>
				<div class="flex backface-hidden touch-pan-y touch-pinch-zoom ml-[calc(12px*-1)]">
					<IndexVillagesItem
						v-for="item in data?.data"
						:key="item.id"
						:item="item"
						class="flex-[0_0_290px] min-w-0 ml-[12px]"
					/>
				</div>
			</div>
			<UButton
				v-if="data?.data?.length > 3"
				variant="link"
				class="absolute max-md:hidden left-2 top-1/2 z-50 h-12 text-gray-600 -mt-6 disabled:opacity-50 disabled:cursor-default disabled:pointer-events-auto"
				aria-label="Previous"
				@click="onPrev"
			>
				<UIcon name="i-app-arrow-prev" class="w-[16px] h-[35px]" />
			</UButton>
			<UButton
				v-if="data?.data?.length > 3"
				variant="link"
				class="absolute max-md:hidden right-2 top-1/2 z-50 h-12 -mt-6 text-gray-600 disabled:opacity-50 disabled:cursor-default disabled:pointer-events-auto"
				aria-label="Next"
				@click="onNext"
			>
				<UIcon name="i-app-arrow-next" class="w-[16px] h-[35px]" />
			</UButton>
		</div>
	
	</LayoutContainer>
</template>

<i18n>
{
	"ru": {
		"title": "Популярные поселки"
	}
}
</i18n>